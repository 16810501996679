const Privacy = () => {
	return (
		<div className="max-w-[1440px] mx-auto pt-[15px] md:pt-[30px] pb-[30px] md:pb-[50px] px-5 xl:px-[60px]">
			<div className="flex flex-col space-y-5">
				<span className="font-semibold text-[30px]">Privacy Policy</span>
				<span className="text-black9">
					Privacy Statement
					<br />
					The Privacy Statement pertains to the services (including all services, software applications, and websites this Privacy Statement is applicable to, hereinafter referred to as "Services") managed by Dopple Labs Inc. and its affiliates (collectively referred to as “Dopple,” “we”, “us” or “our”).
					<br /><br />
					This Privacy Statement elucidates what Personal Information (defined below) we gather, the manner in which we utilize and share that information, and the choices you have regarding our data practices. This Privacy Statement is integrated into and forms part of our Terms of Service.
					<br /><br />
					Please carefully review this Privacy Statement before utilizing the Services or submitting any Personal Information to Dopple. Your agreement to the practices described in this Privacy Statement is implied by your use of the Services. If you disagree with this Privacy Statement, you are advised not to access any of our Websites or use the Service in any way.
					<br /><br />
					1. Collection of Personal Information
					<br />
					We gather information that alone or in conjunction with other information in our possession could be used to identify you (“Personal Information”) as follows:
					<br /><br />
					Information You Provide. This includes Personal Information you provide when you create an account or communicate with us.
					<br /><br />
					Communication Information. If you engage in communication with us, we may collect your name, contact information, and the contents of any messages you send (“Communication Information”).
					<br /><br />
					Information from Our Social Media Pages. We have pages on social media sites like Instagram, Facebook, Medium, Twitter, YouTube, and LinkedIn (“Social Media Pages”). When you interact with our Social Media Pages, we collect Personal Information that you elect to provide to us, such as your contact details (“Social Information”). The companies that host our Social Media Pages may also provide us with aggregate information and analytics regarding the use of our Social Media Pages.
					<br /><br />
					Information from Your Use of the Service. When you visit, use, and interact with the Service, we may receive certain information about your visit, use, or interactions (“Technical Information”). This includes log data, cookies, device information, and usage information.
					<br /><br />
					Cookies. We use cookies to operate and administer our Site, gather usage data on our Site, and improve your experience on it. Please refer to the "Cookies" section for more details.
					<br /><br />
					Analytics. We may use a variety of online analytics products that use cookies to help us analyze how users use the Site and enhance your experience when you use the Site.
					<br /><br />
					Online Tracking and Do Not Track Signals. We and our third party service providers may use cookies or other tracking technologies to collect information about your browsing activities over time and across different websites following your use of the Site.
					<br /><br />
					Your Choices. Most web browsers have a “help” section on the toolbar that provides information on how to receive a notification when you are receiving a new cookie and how to turn cookies off.
					<br /><br />
					Advertising networks may use cookies to collect Personal Information. Most advertising networks offer you a way to opt out of targeted advertising.
					<br /><br />
					When you input content into the Services, we may monitor what you write to check for inappropriate content or Personal Information.
					<br /><br />
					2. Use of Personal Information
					<br />
					We may use Personal Information for various purposes like providing and administering access to the Services, informing you about features of the Services, responding to your inquiries, analyzing how you interact with our Service, developing new programs and services, and complying with legal obligations and legal process.
					<br /><br />
					Aggregated Information. We may aggregate Personal Information and use the aggregated information for analyzing the effectiveness of our Services, improving and adding features to our Services, and conducting research.
					<br /><br />
					3. Disclosure of Personal Information
					<br />
					In certain circumstances, we may share your Personal Information with third parties without further notice to you, unless required by law.
					<br /><br />
					4. Updating Your Information
					<br />
					If you need to change or correct your Personal Information, please contact us at{"\u00a0"}<a className="font-bold text-blue2" href="mailto:support@dopple.ai">support@dopple.ai</a>.
					<br /><br />
					5. Age Requirement
					<br />
					If you are under 13
					<br /><br />
					Privacy Statement
					<br />
					This Privacy Statement applies to the services (including all services, software applications, and websites this Statement is posted on, henceforth referred to as the "Services") managed by Dopple Labs Inc. and its associated entities (collectively referred to as "Dopple," "we", "us" and/or "our").
					<br /><br />
					This Privacy Statement outlines the types of Personal Information (defined later) we gather, how we utilize and share this information, and the choices you have regarding our data practices. This Statement is a component of our Terms of Service.
					<br /><br />
					Before employing our Services or submitting any Personal Information to Dopple, please thoroughly review this Privacy Statement. By using the Services, you consent to the practices outlined in this Privacy Statement. If you disagree with this Privacy Statement, you may not access our Websites or otherwise use the Service.
					<br /><br />
					1. The Personal Information We Gather
					<br />
					We collect data that, on its own or combined with other information in our possession, could be used to identify you ("Personal Information"), as follows:
					<br /><br />
					Information You Provide: We may gather Personal Information if you establish an account or engage with us.
					<br /><br />
					Communication Data: If you interact with us in other ways, we may gather your name, contact details, and the contents of any messages you send ("Communication Information").
					<br /><br />
					Information Collected Via Our Social Media Pages: We have pages on social media platforms like Instagram, Facebook, Medium, Twitter, YouTube, and LinkedIn ("Social Media Pages"). Interaction with our Social Media Pages can result in us collecting Personal Information that you choose to provide, such as your contact details ("Social Information"). Additionally, the companies hosting our Social Media Pages may provide us with aggregated data and analytics about the use of our Social Media Pages, potentially including your engagement details with our social media sites.
					<br /><br />
					Automatically Collected Information From Your Use of the Service: When you visit, use, and interact with the Service, we may receive certain information about your visit, use, or interactions ("Technical Information"). This can include log data, device information, usage information, and cookies. Please refer to the "Cookies" section below for more details.
					<br /><br />
					Analytics: We may use various online analytics products (such as Google Analytics) that use cookies to help us analyze user behavior on the Site and enhance your experience.
					<br /><br />
					Online Tracking and Do Not Track Signals: We and our third-party service providers may use cookies or other tracking technologies to collect information about your browsing activities over time and across different websites following your use of the Site. Our Site currently does not respond to “Do Not Track” (“DNT”) signals.
					<br /><br />
					Your Choices: You can manage your cookie settings in most web browsers. Please note that if you restrict the ability of websites to set cookies, your user experience may be affected.
					<br /><br />
					2. How We Utilize Personal Information
					<br />
					Personal Information may be used for the following purposes:
					<br /><br />
					To provide, manage, enhance, or repair any aspect of the Services; to inform you about Services features we believe you might find interesting; to respond to your inquiries, comments, feedback, or questions; to send administrative information to you; to analyze your interaction with our Service; to develop new programs and services; to prevent fraud, criminal activity, or misuses of our Service, and to ensure the security of our IT systems, architecture, and networks; and to comply with legal obligations and legal process.
					<br /><br />
					3. Sharing and Disclosure of Personal Information
					<br />
					Under certain circumstances, we may share your Personal Information with third parties without further notice to you, unless required by law.
					<br /><br />
					4. Update Your Information
					<br />
					Please contact us at{"\u00a0"}<a className="font-bold text-blue2" href="mailto:support@dopple.ai">support@dopple.ai</a> if you need to update or correct your Personal Information.
					<br /><br />
					5. Age Requirement
					<br />
					Users under 13 years old, or EU citizens or residents under 16 years old, are not authorized to register to use the Services.
					<br /><br />
					6. Links to Third
					<br /><br />
					Privacy Policy Revision
					<br /><br />
					The Privacy Policy set forth here applies to the services provided by Dopple Labs Inc. and its associated companies (referred to as “Dopple,” “we”, “us”, or “our”). These services include, but are not limited to, all software applications and websites where this Privacy Policy is displayed (hereafter referred to as the “Services”).
					<br /><br />
					This Privacy Policy clarifies the types of Personal Information we collect, how we utilize and share such information, and the choices you have regarding our data practices. This Privacy Policy is an integral part of our Terms of Service.
					<br /><br />
					Before utilizing our Services or submitting any Personal Information to Dopple, we encourage you to carefully review this Privacy Policy. By using our Services, you consent to the practices outlined in this Privacy Policy. If you disagree with this Privacy Policy, we kindly request that you refrain from using our Services.
					<br /><br />
					1. Types of Personal Information We Collect
					<br />
					We collect information that could potentially identify you, either alone or in combination with other data we hold (“Personal Information”). The following are ways we collect such information:
					<br /><br />
					Provided Personal Information. We may gather Personal Information if you create an account or engage with us.
					<br /><br />
					Communication Data. When you interact with us, we may collect your name, contact details, and the content of your messages (“Communication Information”).
					<br /><br />
					Data Collected via Social Media Pages. We operate pages on various social media platforms, such as Instagram, Facebook, Medium, Twitter, YouTube, and LinkedIn (“Social Media Pages”). When you interact with these pages, we may collect Personal Information that you choose to provide to us, which can include your contact information (“Social Information”). Additionally, the companies hosting our Social Media Pages may supply us with aggregate data and analytics about the usage of our pages.
					<br /><br />
					Technical Information. When you visit, use, or interact with our Services, we may gather certain data about your activity (“Technical Information”). For instance, we monitor visitor numbers, peak visit times, the pages visited, the domains our visitors originate from, the browsers used to access our Services, broad geographical information, and user navigation patterns. Technical Information encompasses the following data, which is automatically logged in our systems:
					<br /><br />
					Log data. This is information that your browser automatically transmits whenever you visit the Site (“log data”). It includes your IP address, browser type and settings, the date and time of your request, and your interaction with the Site.
					<br />
					Cookies. We use cookies to run and administer our Site, gather usage data, and enhance your experience. For more information on cookies, please refer to the “Cookies” section below.
					<br />
					Device information. This includes the name of the device, operating system, and browser you are using. The information collected may depend on the type of device you use and its settings.
					<br />
					Usage Information. We collect information about how you use our Service, such as the types of content that you view or engage with, the features you use, the actions you take, and the time, frequency, and duration of your activities.
					<br /><br />
					Cookies. We employ cookies to manage and administer our Site, collect usage data, and improve your user experience. A "cookie" is a piece of data sent to your browser by a website you visit. Cookies can be stored on your computer for varying lengths of time. Some cookies expire after a certain period or upon logging out (session cookies), while others persist after your browser is closed until a set expiry date (persistent cookies). They help recognize your computer when you open your browser and browse the internet again. For more information on cookies, please visit All About Cookies.
					<br /><br />
					Analytics. We may use various online analytics products (e.g., Google Analytics) that employ cookies to help us analyze user activity on the Site and enhance your experience.
					<br /><br />
					2. How We Handle Your Personal Information
					<br />
					We utilize your Personal Information for various reasons:
					<br /><br />
					- To give and manage your access to our services
					<br />
					- To maintain, enhance or repair any aspect of our services, which may remain internal or may be shared with third parties if necessary
					<br />
					- To inform you about services or features we believe might be of interest to you
					<br />
					- To respond to your inquiries, comments, feedback, or questions
					<br />
					- To send administrative information to you, such as updates regarding our services or changes to our terms, conditions, and policies
					<br />
					- To analyze how you interact with our Service
					<br />
					- To develop new programs and services
					<br />
					- To prevent fraud, criminal activity, or misuse of our Service, and to ensure the security of our IT systems, architecture, and networks
					<br />
					- To comply with legal obligations and legal process and to protect our rights, privacy, safety, or property, and/or that of our affiliates, you, or other third parties.
					<br /><br />
					We may also aggregate Personal Information and use this information to analyze the effectiveness of our Services, to improve and add features to our Services, and for other similar purposes. From time to time, we may analyze the general behavior and Doppleistics of users of our Services and share such aggregated information like general user statistics with third parties, or make this aggregated information publicly available. This aggregated information may be collected through the Service, through cookies, and other means described in this Privacy Policy.
					<br /><br />
					3. Sharing and Disclosure of Personal Information
					<br />
					Your Personal Information might be shared with third parties under certain circumstances without further notice to you, unless required by the law, as detailed below:
					<br /><br />
					- Service Providers: We may share your information with vendors and service providers to assist us in meeting our business operational needs and to perform certain services and functions. These may include providers of hosting services, cloud services, and other information technology services providers, email communication software and email newsletter services, advertising and marketing services, and web analytics services. Following our instructions, these parties will access, process, or store Personal Information in the course of performing their duties to us.
					<br />
					- Business Transfers: If we are involved in a significant business event such as a merger, reorganization, bankruptcy, receivership, or service transition, your Personal Information may be shared with counterparties and others involved in the event and may be transferred to a successor or affiliate as part of the event, along with other assets.
					<br />
					- Legal Requirements: We may share your information if we are legally required to do so or if we believe it is necessary to comply with a legal obligation, protect and defend our rights or property, prevent fraud, act in urgent circumstances to protect personal safety, or protect against legal liability.
					<br />
					- Affiliates: We may share your information with our affiliates, meaning entities that control, are controlled by, or are under common control with Dopple. Our affiliates may use your information in a manner that is consistent with this Privacy Policy.
					<br />
					- Other Users: Certain actions you take may be visible to other users of the Services, or result in content that is made available to other users of the Services.
					<br /><br />
					4. Updating Your Information
					<br />
					Should you need to modify or correct your Personal Information, please get in touch with us at{"\u00a0"}<a className="font-bold text-blue2" href="mailto:support@dopple.ai">support@dopple.ai</a>.
					<br /><br />
					5. Age Requirement
					<br />
					The Services are not intended for use by individuals under the age of 13 or, if you're a resident or citizen of the European Union, under the age of 16. We do not knowingly collect Personal Information from these age groups. If you believe a child under these ages has provided Personal Information to us through the Services, please contact us at{"\u00a0"}<a className="font-bold text-blue2" href="mailto:support@dopple.ai">support@dopple.ai</a> so we can investigate.
					<br /><br />
					6. Links to Third-Party Websites and Services
					<br />
					Our Service may
					<br /><br />
					6. Third-Party Links
					<br />
					The service may contain links to third-party websites not under our control, including social media platforms. The privacy policies and terms of these third-party sites, not our Privacy Policy, will govern the information you share with them. We provide these links as part of our service but do not imply endorsement or review of these sites. Please contact these third-party sites directly for their privacy practices and policies.
					<br /><br />
					7. Modifications to this Privacy Policy
					<br />
					Our services and business activities may evolve over time, which may necessitate changes to this Privacy Policy. When such changes occur, we will update the policy on this page, or provide a different form of notice if required by law. By continuing to use our services or providing us with personal information after we have posted an updated Privacy Policy, you are agreeing to the revised policy and practices contained within it. We encourage periodic review of this policy.
					<br /><br />
					8. Reach Out to Us
					<br />
					If you have any queries regarding our Privacy Policy or information practices, feel free to get in touch with us at {"\u00a0"}<a className="font-bold text-blue2" href="mailto:support@dopple.ai">support@dopple.ai</a>.
				</span>
			</div>
		</div>
	)
}

export default Privacy