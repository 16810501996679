import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    openUsernameModal: false,
    openSignModal: false,
    openVerifyModal: false,
    openBioModal: false,
    openChatModal: false,
    openMenuModal: false,
    openShareModal: false,
    openChatSettingsModal: false,
    openWaitlistModal: false,
    openForgetPasswordModal: false,
    details: {},
}

export const ModalReducer = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        setOpenVerifyModal: (state, action) => {
            state.openVerifyModal = !state.openVerifyModal
        },
        setOpenForgetPasswordModal: (state, action) => {
            state.openForgetPasswordModal = !state.openForgetPasswordModal
        },
        setOpenUsernameModal: (state, action) => {
            state.openUsernameModal = !state.openUsernameModal
        },
        setOpenSignModal: (state, action) => {
            state.openSignModal = !state.openSignModal
        },
        setOpenShareModal: (state, action) => {
            state.openShareModal = !state.openShareModal
        },
        setOpenWaitlistModal: (state, action) => {
            state.openWaitlistModal = !state.openWaitlistModal
        },
        setOpenChatSettingsModal: (state, action) => {
            state.openChatSettingsModal = !state.openChatSettingsModal
        },
        setOpenMenuModal: (state, action) => {
            state.openMenuModal = !state.openMenuModal
        },
        setDetails: (state, action) => {
            state.details = action.payload
        },
        setReferralDopple: (state, action) => {
            state.referral = action.payload
        }
    },
})

export const { setOpenVerifyModal, setOpenForgetPasswordModal, setOpenUsernameModal, setOpenSignModal, setOpenChatSettingsModal, setOpenShareModal, setOpenWaitlistModal, setOpenMenuModal, setDetails, setReferralDopple } = ModalReducer.actions

export default ModalReducer.reducer