import { useContext, useMemo, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import useWindowDimensions from "../hooks/useDimensions";
import Footer from "./Footer";
import Header from "./Header";
import Nav from "./Nav";
import SignModal from "../components/SignModal";
import ShareModal from "../components/ShareModal";
import MenuModal from "../components/MenuModal";
import WaitlistModal from "../components/WaitlistModal";
import ChatSettingsModal from "../components/ChatSettingsModal";
import UsernameModal from "../components/UsernameModal";
import { RefContext } from "../contexts/RefContextProvider";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { setProfile } from "../redux/reducers/AuthReducer";
import axios from "../utilities/axiosConfig";
import ForgetPasswordModal from "../components/ForgetPasswordModal";
import VerifyModal from "../components/VerifyModal";

const Layout = ({ children }) => {
  const dispatch = useDispatch()
  const [openSearch, setOpenSearch] = useState(false);
  const screenWidth = useWindowDimensions().width;
  const screenHeight = useWindowDimensions().height;
  const location = useLocation();
  const matches = useMediaQuery('(min-width:1024px)');
  const { themeID, setThemeID, isTyping, isTypingSearch, dopple, setAllUsers } = useContext(RefContext);
  const profile = useSelector(store => store.AuthReducer.profile);
  const [cookies, setCookies] = useCookies(["profile", "themeid"])
  useMemo(() => {
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    if (screenWidth > 0 && screenHeight > 0) appHeight()
  }, [screenWidth, screenHeight])
  useMemo(() => {
    if (themeID === 0) document.body.style.background = "#141518"
    else document.body.style.background = "white"
  }, [themeID])
  useMemo(() => {
    if (profile && !cookies.profile) {
      setCookies("profile", JSON.stringify(profile))
    }
  }, [profile, cookies.profile])
  useMemo(async() => {
    if (cookies.profile && cookies.profile.id) {
      const data = await axios.get(`/user/${cookies.profile.id}`)

      if (!data.data?.success) {
        return;
      }

      const userData = data.data.data;
      setAllUsers([userData])
      dispatch(setProfile(userData))
    }
  }, [cookies.profile])
  useMemo(() => {
    if (cookies?.themeid) setThemeID(parseInt(cookies?.themeid))
    else setThemeID(0)
  }, [cookies?.themeid, setThemeID])
  useMemo(() => {
    const handleTouchMove = (e) => {
      e.preventDefault();
    }
    document.addEventListener("touchmove", handleTouchMove)
  }, [])
  return (
    <div className={"jeans flex flex-col relative overflow-hidden" + (location.pathname !== "/messages" ? " bg-black15" :(themeID === 0 ? " bg-black15" : themeID === 1 ? " bg-white1" : themeID === 2 ? " bg-candybutton" : themeID === 3 ? (!dopple && !matches ? " bg-spaceeco bg-cover" : " bg-galaxynav") : "") + (location.pathname === "/messages" ? " h-[100svh]" : " min-h-[100svh]"))}>
      {location.pathname !== "/messages" &&
        <Header openSearch={openSearch} setOpenSearch={setOpenSearch} />
      }
      <main className={"flex-1 relative" + (location.pathname === "/messages" ? " h-0" : "")}>
        <div className="h-full">
          {children}
        </div>
      </main>
      {(location.pathname !== "/messages" && location.pathname !== "/community") && <Footer openSearch={openSearch} />}
      {((!isTyping && !dopple) || location.pathname !== "/messages") && <Nav openSearch={openSearch} />}
      {openSearch &&
        <div className={`block md1:hidden absolute w-full h-full bg-menuback transition-all z-[3] backdrop-blur-[5px]`} />
      }
      <MenuModal />
      <SignModal />
      <WaitlistModal />
      <ShareModal />
      <UsernameModal />
      <ForgetPasswordModal />
      <VerifyModal />
      {matches && <ChatSettingsModal />}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
