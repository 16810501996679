import { useState } from "react";
import { ClickAwayListener } from "@mui/material";
import CopyButtonMobile from "./CopyButtonMobile";
import AudioPlayer from "./AudioPlayer";

const MessageFromBotMobile = ({ themeID, alignment, textSize, x, i, editedIndexes, setEditIndex, isLast, reroll }) => {
    const [open, setOpen] = useState(false);
    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div className={"msg-para group border rounded-[20px] rounded-tl-[15px] rounded-bl-[0px] p-[10px] min-w-[40px] " + (alignment === 0 && x?.message?.type === "human" ? "mr-[10px]" : "ml-[10px]") + (x?.message?.data.content?.length <= 7 ? " text-center" : "") + (themeID === 0 ? " bg-button border-[#363941]" : themeID === 1 ? " bg-[#EDEDF0] border-[#C4C7CB] text-title" : themeID === 2 ? " bg-candysubtext border-[#FF71CE]" : themeID === 3 ? " bg-[#322995] border-[#453CB9]" : "") + (x?.message?.data?.url ? " max-w-full" : " max-w-[70%]")} style={{ fontSize: textSize + "px", lineHeight: (textSize + 3) + "px" }}
                onClick={() => setOpen(true)}
            >
                {x?.message?.data?.url ?
                    <AudioPlayer src={x?.message?.data?.url} themeID={themeID} />
                    :
                    <>
                        <div className="flex items-end space-x-[5px]">
                            <span style={{ fontSize: textSize + "px", lineHeight: (textSize + 3) + "px" }}>{x?.message?.data.content}</span>
                            {editedIndexes.some(x => x === i) &&
                                <span className="italic" style={{ fontSize: (textSize - 2) + "px", lineHeight: (textSize + 1) + "px" }}>edited</span>
                            }
                        </div>
                        <div className="w-full h-full absolute top-0 left-0">
                            {isLast &&
                                <svg className={"absolute top-1/2 -translate-y-1/2 cursor-pointer" + (alignment === 1 ? " right-[-34px]" : (x?.message?.type === "ai" ? " right-[-34px]" : " left-[-34px]")) + (themeID === 0 ? " text-subtext" : themeID === 1 ? " text-subtext" : themeID === 2 ? " text-candysubtext" : themeID === 3 ? " text-[#453CB9]" : "")} xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="currentColor" onClick={() => reroll(i)}>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M6.876 15.6239C7.467 16.5926 8.3205 17.374 9.33752 17.8773C10.3545 18.3806 11.4934 18.5853 12.622 18.4676C13.7507 18.35 14.8228 17.9148 15.7142 17.2125C16.6055 16.5103 17.2795 15.5697 17.658 14.4999H19.748C19.3645 15.9823 18.5638 17.3235 17.441 18.3644C16.3181 19.4054 14.9202 20.1023 13.4131 20.3727C11.906 20.643 10.3531 20.4754 8.9384 19.8896C7.52374 19.3038 6.30682 18.3246 5.432 17.0679L3 19.4999V13.4999H9L6.876 15.6239ZM17.125 9.37494C16.5337 8.40611 15.6799 7.62472 14.6626 7.1214C13.6452 6.61807 12.5061 6.41342 11.3772 6.53118C10.2483 6.64894 9.17589 7.08428 8.28434 7.78672C7.3928 8.48916 6.71863 9.42993 6.34 10.4999H4.25C4.63336 9.01705 5.4341 7.67526 6.55719 6.63381C7.68028 5.59237 9.07858 4.89498 10.5861 4.62442C12.0937 4.35386 13.6472 4.52148 15.0624 5.10739C16.4775 5.6933 17.6949 6.6729 18.57 7.92994L21 5.49994V11.4999H15L17.125 9.37494Z" />
                                </svg>
                            }
                        </div>
                        {open &&
                            <div className={"flex items-center space-x-[5px] rounded-[10px] p-[10px] absolute top-[-60px] left-1/2 -translate-x-1/2 dopple-tooltip1" + (themeID === 0 ? " bg-nav shadow-tooltip-dark after:border-t-nav" : themeID === 1 ? " bg-white shadow-tooltip-light after:border-t-white" : themeID === 2 ? " bg-candynav shadow-tooltip-candy after:border-t-candynav" : themeID === 3 ? " bg-galaxynav shadow-tooltip-galaxy after:border-t-galaxynav" : "")}>
                                <CopyButtonMobile txt={x?.message?.data?.content} themeID={themeID} />
                                <button className={"flex justify-center items-center rounded-[5.83px] w-[35px] h-[35px]" + (themeID === 0 ? " bg-button text-subtext" : themeID === 1 ? " bg-[#EDEDF0] text-subtext" : themeID === 2 ? " bg-candybutton text-candysubtext" : themeID === 3 ? " bg-galaxybutton text-galaxysubtext" : "")} onClick={() => setEditIndex(i)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 20H19C19.2652 20 19.5196 20.1054 19.7071 20.2929C19.8946 20.4804 20 20.7348 20 21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21C4 20.7348 4.10536 20.4804 4.29289 20.2929C4.48043 20.1054 4.73478 20 5 20ZM4 15L14 5L17 8L7 18H4V15ZM15 4L17 2L20 5L17.999 7.001L15 4Z" />
                                    </svg>
                                </button>
                            </div>
                        }
                    </>
                }
            </div>
        </ClickAwayListener>
    )
}

export default MessageFromBotMobile;