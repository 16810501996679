const Terms = () => {
	return (
		<div className="max-w-[1440px] mx-auto pt-[15px] md:pt-[30px] pb-[30px] md:pb-[50px] px-5 xl:px-[60px]">
			<div className="flex flex-col space-y-5">
				<span className="font-semibold text-[30px]">Terms of Service</span>
				<span className="text-black9">
					Last updated June 6, 2023
					<br />
					By using our website Dopple.ai ("Website"), related applications ("App"), and any other tools, content, features, and functionalities ("Services") provided by Dopple Labs Inc. ("Company," "Dopple Labs," "we," or "us"), you are entering into an agreement ("Terms of Service" or "Terms") with us.
					<br /><br />
					These Terms dictate the conditions under which you can access and use our Services. It's crucial that you thoroughly read and understand these Terms as they contain significant legal information. By accessing or using our Services, you confirm your acceptance of these Terms. If you find these Terms confusing or if you disagree with them, we kindly ask you not to use our Services.
					<br /><br />
					The terms "you" and "your" refer to you as the user of our Services. If you're using our Services on behalf of an entity, "you" extends to include both you and the entity. As such, you are confirming that (a) you are an authorized representative of that entity with the power to commit the entity to these Terms, and (b) you consent to these Terms on behalf of the entity.
					<br /><br />
					Please note that these Terms include an arbitration clause and a class action waiver. By agreeing to these Terms, you consent (a) to settle all disputes through binding individual arbitration, meaning that you relinquish any right to have those disputes resolved by a judge or jury, and (b) to forego your right to participate in class actions, class arbitrations, or representative actions. The option to opt-out of arbitration is detailed further in these Terms.
				</span>
				<span className="font-semibold text-[20px]">Use of the Services</span>
				<span className="text-black9">
					Registration Requirements. You might need to complete a registration form to access and use certain aspects of our Services. If you decide to register, you agree to supply and keep up-to-date, accurate, and complete information about yourself as required by our registration form. Our Privacy Policy governs the data you provide during registration and other information about you. Please note that if you are under 13 years old OR if you're an EU citizen or resident and are under 16 years old, you are not permitted to register for our Services.
					<br /><br />
					Account, Password, and Security Responsibility. You hold the responsibility for safeguarding your account and password, assuming full responsibility for all activities that occur under your account. You agree to (a) immediately inform Dopple Labs of any unauthorized use of your account or password or any other security breach, and (b) ensure you log out from your account after each session using the Services. Dopple Labs will not be held accountable for any loss or damage resulting from your failure to adhere to this section.
					<br /><br />
					Changes to the Services. Dopple Labs reserves the right to alter or cease, temporarily or permanently, the Services (or any part thereof) with or without prior notice. By using our Services, you accept that Dopple Labs will not be liable to you or any third party for any changes, suspensions, or discontinuation of the Services.
					<br /><br />
					Use and Storage Practices. You acknowledge that Dopple Labs may set general rules and limits regarding the use of the Services, such as the maximum duration that data or other content will be stored by the Services and the maximum storage space allocated to you on our servers. You agree that Dopple Labs bears no responsibility or liability for the removal or failure to store any data or other content you maintain or upload to the Services. Furthermore, you acknowledge that Dopple Labs reserves the right to terminate inactive accounts and to modify these general practices and limits at any time, at its sole discretion, with or without notice.
					<br /><br />
				</span>
				<span className="font-semibold text-[20px]">Conditions Of Use</span>
				<span className="text-black9">
					User Behavior. In addition to adhering to our Acceptable Use Policy ("AUP"), which is an integral part of this agreement, you pledge to abide by the following conditions when using our Services. You are solely accountable for all forms of content ("Content"), including but not limited to code, video, images, data, text, software, music, sound, photographs, graphics, and messages that you upload, post, publish, display ("Upload"), email, or otherwise transmit via our Services. Your use of the Services might be subjected to license and usage restrictions as per the CreativeML Open RAIL-M License.
					<br /><br />
					The following serves as examples of the type of Content and/or usage that Dopple Labs considers to be illegal or prohibited. Dopple Labs reserves the right to scrutinize and take suitable legal action against anyone who breaches this provision at Dopple Labs's sole discretion. Actions may include, but are not limited to, removing the offending Content from the Website or Services, suspending or terminating the account of the violators, and reporting them to the relevant law enforcement authorities.
					<br /><br />
					You agree to not use the Website or Services to:
					<br />
					<ul>
						<li>upload or transmit any Content that: (i) infringes any intellectual property or other proprietary rights of any party; (ii) you do not have a right to upload under any law or under contractual or other relationships; (iii) contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment; (iv) poses or creates a privacy or security risk to any person; (v) constitutes unsolicited or unauthorized advertising, promotional materials, commercial activities and/or sales, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” “contests,” “sweepstakes,” or any other form of solicitation; (vi) is unlawful, harmful, threatening, abusive, harassing, tortious, excessively violent, defamatory, vulgar, obscene, pornographic, libelous, invasive of another’s privacy, hateful racially, ethnically or otherwise objectionable; or (vii) in the sole judgment of Dopple Labs, is objectionable or which restricts or inhibits any other person from using or enjoying the Website or Services, or which may expose Dopple Labs or its users to any harm or liability of any type;</li>
						<li>interfere with or disrupt the Website or Services or servers or networks connected to the Website or Services, or disobey any requirements, procedures, policies or regulations of networks connected to the Website or Services;</li>
						<li>violate any applicable local, state, national or international law, or any regulations having the force of law;</li>
						<li>impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity;</li>
						<li>solicit personal information from anyone under the age of 18;</li>
						<li>harvest or collect email addresses or other contact information of other users from the Website or Services by electronic or other means for the purposes of sending unsolicited emails or other unsolicited communications;</li>
						<li>advertise or offer to sell or buy any goods or services for any business purpose that is not specifically authorized;</li>
						<li>further or promote any criminal activity or enterprise or provide instructional information about illegal activities;</li>
						<li>obtain or attempt to access or otherwise obtain any materials or information through any means not intentionally made available or provided for through the Website or Services;</li>
						<li>lease, lend, sell or sublicense any part of the Services;</li>
						<li>try to get around any technological measure designed to protect the Services or any technology associated with the Services;</li>
						<li>reverse engineer, disassemble, decompile, decode, adapt, or otherwise attempt to derive or gain access to any Services source code, in whole or in part (unless a portion of code contained within the Services is released as open source and the open source license governing such code expressly permits reverse engineering, copying or other modification)</li>
						<li>use the Services to create malicious or abusive content (as determined by Dopple Labs in its sole discretion) or any content that violates a Dopple Labs policy; or use the Services (or any part thereof or any technology contained therein) in any manner that infringes, misappropriates, or otherwise violates any intellectual property right or other right of any person, or that violates any applicable laws.</li>
					</ul>
				</span>
				<span className="font-semibold text-[20px]">Intellectual Property Rights</span>
				<span className="text-black9">
					Content You Upload to the Site. Regarding the content or other materials you upload or generate using our Services ("User Content"), you assure and warrant that you possess all rights, title, and interest in such User Content. This includes, but is not limited to, all copyrights and publicity rights contained within, or have permission to use them for the applicable purposes, such as through a license or legal exception or limitation.
					<br /><br />
					When you create or upload any User Content that you own or generate using the Service, you maintain all rights to it (to the extent that you would otherwise have such rights). You also grant, and promise to grant, Dopple Labs, to the fullest extent allowed by law, a non-exclusive, global, royalty-free, fully paid-up, transferable, sublicensable, perpetual, irrevocable license. This license allows Dopple Labs to copy, display, upload, perform, distribute, store, modify, and otherwise use your User Content for any Dopple Labs-related purpose in any form, medium, or technology, whether currently known or developed in the future.
					<br /><br />
					Dopples and Generated Content. For any automated AI entity ("Dopple") you design or upload using the Service, you retain all rights to that Dopple and any derivative works you create, as well as any text, images, or video it produces ("Generations") that are directly or indirectly prompted by you. You grant Dopple Labs and any Dopple Labs user who prompts Generations from your Dopple the broadest possible nonexclusive, global, royalty-free, fully paid up, transferable, sublicensable, perpetual, irrevocable license to copy, display, upload, perform, distribute, store, modify, and use your Dopple and any associated Generations in any form or medium, either existing now or developed in the future.
					<br /><br />
					A user interacting with a Dopple created by another user or Dopple Labs owns all rights to the Generations they prompt from a Dopple. They grant Dopple Labs and the original Dopple creators a similar license to use any Generations. To clarify, violations of Dopple Labs's Acceptable Use Policy that are unrelated to intellectual property issues don't affect the user's intellectual property rights in any Dopples or their Generations.
					<br /><br />
					Website, Services, and Trademark Content. You acknowledge and agree that the Website or Services may have features or content (“Site Content”) that are protected by various rights and laws such as copyright, patent, trademark, trade secret, or other proprietary rights. You agree not to use any data mining, robots, scraping or similar automated data gathering or extraction methods in connection with your use of the Website or Services. If Dopple Labs blocks your access to the Website or Services (including by blocking your IP address), you agree not to circumvent this block (e.g., by masking your IP address or using a proxy IP address). Unauthorized use of the Website, Services, or Site Content is strictly prohibited. Any rights not expressly granted here are reserved by Dopple Labs.
					<br /><br />
					The Dopple Labs name and logos are trademarks of Dopple Labs (collectively the “Dopple Labs Trademarks”). Other trademarks displayed on the Website or Services may belong to their respective owners, who may or may not be affiliated with or endorsed by Dopple Labs. These Terms or the Website or Services should not be construed as granting any rights to use Dopple Labs Trademarks without our prior written permission. All goodwill generated from the use of Dopple Labs Trademarks will benefit us exclusively.
					<br /><br />
					Third-Party Content. Dopple Labs will not be held liable for any third-party content or materials, including Dopple bots created by third parties and any Generations they produce. This includes, but isn't limited to, any issues of intellectual property rights infringement, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of such content. You acknowledge that Dopple Labs does not pre-screen content, but reserves the right to refuse or remove any content available via the Website or Services. This includes any content that violates these Terms, our AUP, or is considered objectionable by Dopple Labs. You agree that you are responsible for evaluating and assuming all risks associated with the use of any content.
					<br /><br />
					Any questions, comments, suggestions, ideas, feedback, or other information about the Website or Services (“Submissions”) provided by you to Dopple Labs are non-confidential. Dopple Labs has the right to use and distribute these Submissions freely, without acknowledgment or compensation to you.
					<br /><br />
					Without limiting the foregoing, you acknowledge and agree that Dopple Labs may preserve content and may also disclose content and/or metadata if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to: (a) comply with legal process, applicable laws or government requests; (b) enforce these Terms; (c) respond to claims that any content violates the rights of third parties; or (d) protect the rights, property, or personal safety of Dopple Labs, its users and the public. You understand that the technical processing and transmission of the Website and Services, including your content, may involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices.
					<br />
					Copyright Complaints. Dopple Labs respects the intellectual property of others, and we ask our users to do the same. If you believe that your work has been copied in a way that constitutes copyright infringement, or that your intellectual property rights have been otherwise violated, you should notify Dopple Labs of your infringement claim in accordance with the procedure set forth below.
					<br />
					Dopple Labs will process and investigate notices of alleged infringement and will take appropriate actions under the Digital Millennium Copyright Act (“DMCA”) and other applicable intellectual property laws with respect to any alleged or actual infringement. A written notification of claimed copyright infringement should be mailed to:
					<br /><br /><br />
					Dopple Labs, Inc.
					<br />
					251 Little Falls Dr.
					<br />
					Wilmington, DE 19808
					<br />
					Attn: Legal
					<br />
					<a className="underline" href="mailto:support@Dopple.ai" target="_blank" rel="noreferrer">support@Dopple.ai</a>
				</span>
				<span className="text-black9">
					For your notification to be valid, it needs to be in writing and include: your electronic or physical signature as the person qualified to represent the copyright or other intellectual property owner; a detailed description of the copyrighted work or other intellectual property you believe has been violated; a clear guide to where this material is located on our site; your contact details, including address, phone number, and email; a statement by you asserting your honest belief that the use in question isn't sanctioned by the copyright or intellectual property owner, its agent, or the law; and a statement by you, under penalty of perjury, affirming the accuracy of your notice's information and your authorization to act on behalf of the copyright or intellectual property owner.
					<br /><br />
					Counter-Notice: If you think your User Content was wrongly removed or disabled because it's non-infringing, or you have permission from the copyright owner, its agent, or the law to upload and use this content, you can send a counter-notice in writing. This should include: your physical or electronic signature; identification and original location of the content that's been removed or disabled; a statement from you declaring your honest belief that the content was removed or disabled due to an error or misidentification; your contact information; and a statement that you agree to the jurisdiction of the federal court in the Court of Delaware and will accept service of process from the person who first reported the alleged infringement. If the Copyright Agent gets your counter-notice, Dopple Labs will send a copy to the original complainant, letting them know that they may replace the removed content or stop disabling it in 10 business days. Unless the copyright owner seeks a court order against the content provider, member, or user, the removed content may be restored or access to it reinstated 10 to 14 business days or more after getting the counter-notice, at our sole discretion.
					<br /><br />
					Repeat Infringer Policy: Dopple Labs adheres to the DMCA and other relevant laws and will, when deemed necessary and at its sole discretion, terminate the accounts of users who repeatedly infringe. Dopple Labs can also limit access to the Services and/or terminate the accounts of any users who violate others' intellectual property rights, regardless of whether repeat infringement is involved.
				</span>
				<span className="font-semibold text-[20px]">Third Party Websites and Services</span>
				<span className="text-black9">
					The Services may provide, or third parties may provide, links or other access to other sites and resources on the Internet or to third-party applications. Dopple Labs has no control over such sites, resources or applications and Dopple Labs is not responsible for and does not endorse such sites, resources or applications. You further acknowledge and agree that Dopple Labs will not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any content, events, goods or services available on or through any such sites, resources or applications. Any dealings you have with third parties found while using the Services are between you and the third party, and you agree that Dopple Labs is not liable for any loss or claim that you may have against any such third party.
				</span>
				<span className="font-semibold text-[20px]">Indemnity and Release</span>
				<span className="text-black9">
					You agree to release, indemnify and hold Dopple Labs and its affiliates and their officers, employees, directors and agents harmless from any from any and all losses, damages, expenses, including reasonable attorneys’ fees, rights, claims, actions of any kind and injury (including death) arising out of or relating to your use of the Services or any related information, any User Content, your application(s) to Dopple Labs or the results thereof, your violation of these Terms of Use or your violation of any rights of another.
					<br />
					If you are a California resident, you waive California Civil Code Section 1542, which says:
					<br />
					A general release does not extend to claims that the creditor or releasing party does not know or suspect to exist in his or her favor at the time of executing the release and that, if known by him or her, would have materially affected his or her settlement with the debtor or released party.
					<br />
					If you are a resident of another jurisdiction, you waive any comparable statute or doctrine.
				</span>
				<span className="font-semibold text-[20px]">Disclaimer of Warranty</span>
				<span className="text-black9">
					Your utilization of the Service is entirely at your own risk. The site is offered on an "AS IS" and "AS AVAILABLE" basis. Dopple Labs categorically renounces all forms of warranties, be they explicit, implicit, or statutory, including but not limited to the implied warranties of saleability, suitability for a specific purpose, title, and freedom from infringement. Dopple Labs provides no guarantee that (i) the Services will meet your needs, (ii) the Services will be continuous, on time, secure, or free from errors, or (iii) the outcomes derived from the use of the Services will be precise or dependable.
				</span>
				<span className="font-semibold text-[20px]">Limitation of Liability</span>
				<span className="text-black9">
					You expressly understand and agree that Dopple Labs will not be liable for any indirect, incidental, special, consequential, exemplary damages, or damages for loss of profits including but not limited to, damages for loss of goodwill, use, data or other intangible losses (even if Dopple Labs has been advised of the possibility of such damages), whether based on contract, tort, negligence, strict liability or otherwise, resulting from: (I) the use or the inability to use the Services or any related information; (ii) unauthorized access to or alteration of your transmissions or data; (iii) statements or conduct of any third party (including users) on the Services; or (iv) any other matter relating to the Services. In no event will Dopple Labs’s total liability to you for all damages, losses or causes of action exceed one hundred dollars ($100).
					<br />
					Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the above limitations set forth above may not apply to you. If you are dissatisfied with any portion of the site or with these terms of use, your sole and exclusive remedy is to discontinue use of the Services.
				</span>
				<span className="font-semibold text-[20px]">Dispute Resolution By Binding Arbitration</span>
				<span className="text-black9">
					This section affects your rights so please read it carefully.
				</span>
				<span className="font-medium text-[16px]">Agreement to Arbitrate</span>
				<span className="text-black9">
					This Dispute Resolution by Binding Arbitration section of the Terms is referred to in these Terms as the “Arbitration Agreement.” You agree that any and all disputes or claims that have arisen or may arise between you and Dopple Labs, whether arising out of or relating to these Terms (including any alleged breach thereof), the Website or Services, any aspect of the relationship or transactions between us, shall be resolved exclusively through final and binding arbitration, rather than a court, in accordance with the terms of this Arbitration Agreement, except that you may assert individual claims in small claims court, if your claims qualify. Further, this Arbitration Agreement does not preclude you from bringing issues to the attention of federal, state, or local agencies, and such agencies can, if the law allows, seek relief against us on your behalf. You agree that, by entering into these Terms, you and Dopple Labs are each waiving the right to a trial by jury or to participate in a class action. Your rights will be determined by a neutral arbitrator, not a judge or jury. The Federal Arbitration Act governs the interpretation and enforcement of this Arbitration Agreement.
				</span>
				<span className="font-medium text-[16px]">Prohibition of Class and Representative Actions and Non-Individualized Relief</span>
				<span className="text-black9">
					You and Dopple Labs agree that each of us may bring claims against the other only on an individual basis and not as a plaintiff or class member in any purported class or representative action or proceeding. Unless both You and Dopple Labs agree otherwise, the arbitrator may not consolidate or join more than one person’s or party’s claims and may not otherwise preside over any form of a consolidated, representative, or class proceeding. Also, the arbitrator may award relief (including monetary, injunctive, and declaratory relief) only in favor of the individual party seeking relief and only to the extent necessary to provide relief necessitated by that party’s individual claim(s).
				</span>
				<span className="font-medium text-[16px]">Pre-Arbitration Dispute Resolution</span>
				<span className="text-black9">
					Dopple Labs is always interested in resolving disputes amicably and fairly, and so if you have concerns, we strongly encourage you to first email us about them at support@Dopple.ai. If such efforts prove unsuccessful, a party who intends to seek arbitration must first send to the other, by certified mail, a written Notice of Dispute (“Notice”). The Notice to Dopple Labs should be sent to Dopple Labs Inc. 251 Little Falls Dr., Wilmington, DE 19808 (“Notice Address”). The Notice must (i) describe the nature and basis of the claim or dispute and (ii) set forth the specific relief sought. If Dopple Labs and you do not resolve the claim within sixty (60) calendar days after the Notice is received, you or Dopple Labs may commence an arbitration proceeding. During the arbitration, the amount of any settlement offer made by Dopple Labs or you shall not be disclosed to the arbitrator until after the arbitrator determines the amount, if any, to which you or Dopple Labs is entitled.
				</span>
				<span className="font-medium text-[16px]">Arbitration Procedures</span>
				<span className="text-black9">
					Arbitration will be conducted by a neutral arbitrator in accordance with JAMS’ (“JAMS”) Streamlined Arbitration Rules and Procedures (collectively, the “JAMS Rules”), as modified by this Arbitration Agreement. For information on JAMS, please visit its website, https://www.jamsadr.com/. If there is any inconsistency between any term of the JAMS Rules and any term of this Arbitration Agreement, the applicable terms of this Arbitration Agreement will control unless the arbitrator determines that the application of the inconsistent Arbitration Agreement terms would not result in a fundamentally fair arbitration. The arbitrator must also follow the provisions of these Terms as a court would. All issues are for the arbitrator to decide, including, but not limited to, issues relating to the scope, enforceability, and arbitrability of this Arbitration Agreement. Although arbitration proceedings are usually simpler and more streamlined than trials and other judicial proceedings, the arbitrator can award the same damages and relief on an individual basis that a court can award to an individual under the Terms and applicable law. Decisions by the arbitrator are enforceable in court and may be overturned by a court only for very limited reasons.
					<br />
					Unless Dopple Labs and you agree otherwise, any arbitration hearings will take place in Miami, FL. If the parties are unable to agree on a location, the determination shall be made by JAMS. If your claim is for $10,000 or less, Dopple Labs agrees that you may choose whether the arbitration will be conducted solely on the basis of documents submitted to the arbitrator, through a telephonic or video conference hearing, or by an in-person hearing as established by the JAMS Rules. If your claim exceeds $10,000, the right to a hearing will be determined by the JAMS Rules. Regardless of the manner in which the arbitration is conducted, the arbitrator shall issue a reasoned written decision sufficient to explain the essential findings and conclusions on which the award is based.
				</span>
				<span className="font-medium text-[16px]">Costs of Arbitration</span>
				<span className="text-black9">
					Payment of all filing, administration, and arbitrator fees (collectively, the “Arbitration Fees”) will be governed by the JAMS Rules, unless otherwise provided in this Arbitration Agreement.
				</span>
				<span className="font-medium text-[16px]">Confidentiality</span>
				<span className="text-black9">
					All aspects of the arbitration proceeding, and any ruling, decision, or award by the arbitrator, will be strictly confidential for the benefit of all parties.
				</span>
				<span className="font-medium text-[16px]">Severability</span>
				<span className="text-black9">
					If a court or the arbitrator decides that any term or provision of this Arbitration Agreement (other than the subsection (b) titled “Prohibition of Class and Representative Actions and Non-Individualized Relief” above) is invalid or unenforceable, the parties agree to replace such term or provision with a term or provision that is valid and enforceable and that comes closest to expressing the intention of the invalid or unenforceable term or provision, and this Arbitration Agreement shall be enforceable as so modified. If a court or the arbitrator decides that any of the provisions of subsection (b) above titled “Prohibition of Class and Representative Actions and Non-Individualized Relief” are invalid or unenforceable, then the entirety of this Arbitration Agreement shall be null and void. The remainder of the Terms will continue to apply.
				</span>
				<span className="font-medium text-[16px]">Future Changes to Arbitration Agreement</span>
				<span className="text-black9">
					Notwithstanding any provision in this Terms of Use to the contrary, Dopple Labs agrees that if it makes any future change to this Arbitration Agreement (other than a change to the Notice Address) while you are a user of the Services, you may reject any such change by sending Dopple Labs written notice within thirty (30) calendar days of the change to the Notice Address provided above. By rejecting any future change, you are agreeing that you will arbitrate any dispute between us in accordance with the language of this Arbitration Agreement as of the date you first accepted these Terms (or accepted any subsequent changes to these Terms).
				</span>
				<span className="font-semibold text-[20px]">Termination</span>
				<span className="text-black9">
					You agree that Dopple Labs, in its sole discretion, may suspend or terminate your account (or any part thereof) or use of the Services and remove and discard any content within the Website or Services, for any reason, including, without limitation, for lack of use or if Dopple Labs believes that you have violated or acted inconsistently with the letter or spirit of these Terms or Dopple Labs’s Acceptable Use Policy. Dopple Labs may also in its sole discretion and at any time discontinue providing the Services, or any part thereof, with or without notice. You agree that any termination of your access to the Services under any provision of these Terms or our AUP may be effected without prior notice, and acknowledge and agree that Dopple Labs may (but has no obligation to) immediately deactivate or delete your account and all related information and files in your account and/or bar any further access to such files or the Services. Termination of your account or access to any component of the Services will not terminate Dopple Labs’s rights to your User Content. Further, you agree that Dopple Labs will not be liable to you or any third party for any termination of your access to the Services.
				</span>
				<span className="font-semibold text-[20px]">General</span>
				<span className="text-black9">
					Entire Agreement. These Terms constitute the entire agreement between you and Dopple Labs and govern your use of our Services, superseding any prior agreements between you and Dopple Labs with respect to the Services.
					<br />
					Choice of Law, Jurisdiction, Venue. These Terms are governed by the laws of the State of Florida without regard to its conflict of law provisions. With respect to any disputes or claims not subject to arbitration, as set forth above, you and Dopple Labs agree to submit to the personal and exclusive jurisdiction of the state and federal courts located within Miami-Dade County, Florida.
					<br />
					Severance. If any provision of these Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions of these Terms remain in full force and effect.
					<br />
					No Waiver. Any failure of Dopple Labs to exercise or enforce any right or provision of these Terms does not constitute a waiver of such right or provision.
					<br />
					Expiration of Claims. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Site or these Terms of Use must be filed within one (1) year after such claim or cause of action arose or be forever barred.
					<br />
					Admissibility. A printed version of this agreement and of any notice given in electronic form will be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.
					<br />
					Assignment. You may not assign these Terms without the prior written consent of Dopple Labs, but Dopple Labs may assign or transfer these Terms of Use, in whole or in part, without restriction.
					<br />
					Section Headings. The section titles in these Terms of Use are for convenience only and have no legal or contractual effect.
					<br />
					Notice. Notices to you may be made via either email or regular mail. The Site may also provide notices to you of changes to these Terms or other matters by displaying notices or links to notices generally on the Website.
				</span>
				<span className="font-semibold text-[20px]">Changes to these Terms</span>
				<span className="text-black9">
					We hold the right to modify elements of these Terms at our discretion. In the event of such changes, we will display the updates on this page, marking the date of the last revision at the top. Such modifications will not be in effect until a minimum of fourteen (14) days after their posting, with the exception of changes regarding the introduction of new Site features or those necessitated by legal reasons, which will come into immediate effect. If you continue to use the Site subsequent to the activation of any such changes, it signifies your acceptance of the updated Terms.
				</span>
				<span className="font-semibold text-[20px]">Contact Us</span>
				<span className="text-black9">
					If you have any questions about our Services, or to report any violations of these Terms or our Acceptable Use Policies, please contact us at support@Dopple.ai.
				</span>
			</div>
		</div>
	)
}

export default Terms