import { useState } from "react";
import { ClickAwayListener } from "@mui/material";
import CopyButtonMobile from "./CopyButtonMobile";

const MessageFromYouMobile = ({ themeID, alignment, textSize, x, i, editedIndexes, setEditIndex }) => {
    const [open, setOpen] = useState(false);
    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div className={"msg-para border rounded-[20px] p-[10px] min-w-[40px]" + (alignment === 0 ? " mr-[10px] rounded-rounded-tr-[15px] br-[0px] " : " ml-[10px] rounded-tl-[15px] rounded-bl-[0px]") + ((themeID === 0 || themeID === 1) ? (i % 4 === 0 ? " bg-chatback1 border-chatbord1" : i % 4 === 1 ? " bg-chatback2 border-chatbord2" : i % 4 === 2 ? " bg-chatback3 border-chatbord3" : " bg-chatback4 border-chatbord4") + (x?.message?.data.content?.length <= 7 ? " text-center" : "") : (themeID === 2 ? " bg-[#BD32FF] border-[#D171FF]" : themeID === 3 ? " bg-[#7747DC] border-[#9277FF]" : "")) + (x?.message?.data.content?.length <= 7 ? " text-center" : "") + (x?.message?.data?.url ? " max-w-full" : " max-w-[70%]")} style={{ fontSize: textSize + "px", lineHeight: (textSize + 3) + "px" }}
                onClick={() => setOpen(true)}
            >
                <div className="flex items-end space-x-[5px]">
                    <span style={{ fontSize: textSize + "px", lineHeight: (textSize + 3) + "px" }}>{x?.message?.data.content}</span>
                    {editedIndexes.some(x => x === i) &&
                        <span className="italic" style={{ fontSize: (textSize - 2) + "px", lineHeight: (textSize + 1) + "px" }}>edited</span>
                    }
                </div>
                {open &&
                    <div className={"flex items-center space-x-[5px] rounded-[10px] p-[10px] absolute top-[-60px] left-1/2 -translate-x-1/2 dopple-tooltip1" + (themeID === 0 ? " bg-nav shadow-tooltip-dark after:border-t-nav" : themeID === 1 ? " bg-white shadow-tooltip-light after:border-t-white" : themeID === 2 ? " bg-candynav shadow-tooltip-candy after:border-t-candynav" : themeID === 3 ? " bg-galaxynav shadow-tooltip-galaxy after:border-t-galaxynav" : "")}>
                        <CopyButtonMobile txt={x?.message?.data?.content} themeID={themeID} />
                        <button className={"flex justify-center items-center rounded-[5.83px] w-[35px] h-[35px]" + (themeID === 0 ? " bg-button text-subtext" : themeID === 1 ? " bg-[#EDEDF0] text-subtext" : themeID === 2 ? " bg-candybutton text-candysubtext" : themeID === 3 ? " bg-galaxybutton text-galaxysubtext" : "")} onClick={() => setEditIndex(i)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 20H19C19.2652 20 19.5196 20.1054 19.7071 20.2929C19.8946 20.4804 20 20.7348 20 21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21C4 20.7348 4.10536 20.4804 4.29289 20.2929C4.48043 20.1054 4.73478 20 5 20ZM4 15L14 5L17 8L7 18H4V15ZM15 4L17 2L20 5L17.999 7.001L15 4Z" />
                            </svg>
                        </button>
                    </div>
                }
            </div>
        </ClickAwayListener>
    )
}

export default MessageFromYouMobile;