import { Fragment } from "react";
import { getLastMsgDate } from "../utilities/format";

const DoppleRowDesktop = ({ i, x, themeID, dopple, setDopple, contextMenuRef, setOpenContextMenu, pinnedIndexes, setDoppleIndex, pinned }) => {
    const selectDopple = (e) => {
        e.preventDefault()
        if (e.button === 0) {
            if (x._id !== dopple?._id) setDopple(x)
        }
        if (e.button === 2) {
            setDoppleIndex(x._id)
            setOpenContextMenu(true)
            if (contextMenuRef.current) {
                contextMenuRef.current.style.top = e.clientY + "px"
                contextMenuRef.current.style.left = e.clientX + "px"
            }
        }
    }
    return (
        <Fragment>
            <button className={"justify-between items-center px-5 py-[15px] space-x-2 w-full group" + (pinnedIndexes.some(y => y === x._id && !pinned) ? " hidden" : " flex") + (themeID === 0 ? (x._id === dopple?._id ? " bg-button" : " hover:bg-button") : themeID === 1 ? (x._id === dopple?._id ? " bg-[#E7F2FF]" : " hover:bg-[#E7F2FF]") : themeID === 2 ? (x._id === dopple?._id ? " bg-candysubtext" : " hover:bg-candysubtext") : themeID === 3 ? (x._id === dopple?._id ? " bg-galaxybutton" : " hover:bg-galaxybutton") : "")} onMouseDown={selectDopple}>
                <div className="flex space-x-[10px] items-center flex-1 w-[0px]">
                    <img className="w-[50px] h-[50px] rounded-[10px]" src={x.avatarURL + "?tr=w-200,h-200"} alt="" />
                    <div className="flex flex-col items-start space-y-[8.61px] flex-1 w-[0px]">
                        <div className={"flex items-center space-x-[5px] font-bold text-[16px] leading-[19px] w-full" + (themeID === 0 ? "" : themeID === 1 ? " text-title" : themeID === 2 ? (x._id === dopple?._id ? " text-white" : " text-candytitle group-hover:text-white") : themeID === 3 ? " text-galaxytitle" : "")}>
                            <span className="truncate">{x.name}</span>
                            <svg className={(themeID === 0 ? " text-white" : themeID === 1 ? " text-blue2" : themeID === 2 ? (x._id === dopple?._id ? " text-white" : " text-[#FF36F7] group-hover:text-white") : themeID === 3 ? " text-[#5200FF]" : "")} xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="currentColor">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9819 2.41014L9.77743 0L7.46635 1.23424L5.14217 0.0274191L3.9637 2.45166L1.40753 2.90911L1.81179 5.59736L0 7.54436L1.83259 9.46981L1.45722 12.1627L4.01814 12.5899L5.22258 15L7.53365 13.7658L9.85783 14.9726L11.0363 12.5483L13.5925 12.0909L13.1882 9.40264L15 7.45564L13.1674 5.53019L13.5428 2.83733L10.9819 2.41014ZM10.4614 6.40134L9.51696 5.41004L6.95099 8.10297L5.48291 6.5621L4.53841 7.55335L6.95095 10.0855L10.4614 6.40134Z" />
                            </svg>
                        </div>
                        <div className={"flex items-center space-x-[5px] text-[14px] leading-[17px] w-full" + (themeID === 0 ? " text-subtext" : themeID === 1 ? " text-subtext" : themeID === 2 ? (x._id === dopple?._id ? " text-white" : " text-candysubtext group-hover:text-white") : themeID === 3 ? " text-galaxysubtext" : "")}>
                            <span className="truncate flex-1 text-left">{x.chat_history[x.chat_history.length - 1]?.message?.data?.content}</span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-end space-y-[10.55px]">
                    <span className={"text-[12px] leading-[14px]" + (themeID === 0 ? " text-subtext" : themeID === 1 ? " text-subtext" : themeID === 2 ? (x._id === dopple?._id ? " text-white" : " text-candysubtext group-hover:text-white") : themeID === 3 ? " text-galaxysubtext" : "")}>{getLastMsgDate(x.chat_history[x.chat_history.length - 1]?.timestamp * 1000)}</span>
                    {pinned ?
                        <svg className="text-subtext" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M12.9956 0.995608L18.4287 6.42866L13.9056 10.9517L13.8763 12.9722L12.1218 14.7268L4.69749 7.30251L6.45203 5.54797L8.47252 5.5187L12.9956 0.995608Z" />
                            <path d="M7.07131 11.292L3.37028 14.993L4.43094 16.0537L8.13197 12.3526L7.07131 11.292Z" />
                        </svg>
                        :
                        <div className={"flex justify-center items-center w-5 h-5 rounded-full text-[14px] leading-[17px] opacity-0" + (themeID === 0 ? " bg-blue2" : themeID === 1 ? " bg-blue2" : themeID === 2 ? (x._id === dopple?._id ? " bg-white" : " bg-[#FF36F7] group-hover:bg-white") : themeID === 3 ? " bg-[#5200FF]" : "")}>
                            <span className={(themeID === 0 ? "" : themeID === 1 ? "" : themeID === 2 ? (x._id === dopple?._id ? "text-[#FF36F7]" : "group-hover:text-[#FF36F7]") : themeID === 3 ? "" : "")}>2</span>
                        </div>
                    }
                </div>
            </button>
        </Fragment>
    )
}

export default DoppleRowDesktop;